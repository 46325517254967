<div class="material-inventory" *ngIf="materialInfo">
  <div class="material-info grid">
    <div class="material-name col-3">
      <div class="grid">
        {{materialName}}
      </div>
      <div title='copy to clipboard' class="grid" style="font-size:12px;font-weight: normal;margin-top: 0px;"
      (click)='copyToClipboard(materialId)'>
      Repo Id: {{materialId}}
    </div>
    </div>
    <div class="col-7">
      <div class="grid" style="justify-content: right;">

    </div>
      <table *ngIf="this.materialInfo" id="inventory-breakdown">
         <tr>
           <th></th>
           <th></th>
           <th>Physical</th>
           <th>Reserved</th>
           <th>Available</th>
         </tr>
         <tbody>
         <tr>
           <td rowspan=3 class="mi-bold">Stock</td>
           <td>Indianapolis</td>
           <td>{{this.inventoryBreakDown.STOCK.INDY.physical | number : '1.3-3'  }} {{selectedMesaure}}</td>
           <td>{{this.inventoryBreakDown.STOCK.INDY.reserved | number : '1.3-3'  }} {{selectedMesaure}}</td>
           <td>{{this.inventoryBreakDown.STOCK.INDY.available| number : '1.3-3'  }} {{selectedMesaure}}</td>
         </tr>
         <tr>
           <td>{{ euCenterFeature ? "France" : "SGS" }}</td>
           <td *ngIf="euCenterFeature">{{this.inventoryBreakDown.STOCK.FRANCE.physical  | number : '1.3-3'  }} {{selectedMesaure}}</td>
           <td *ngIf="euCenterFeature">{{this.inventoryBreakDown.STOCK.FRANCE.reserved  | number : '1.3-3'  }} {{selectedMesaure}}</td>
           <td *ngIf="euCenterFeature">{{this.inventoryBreakDown.STOCK.FRANCE.available | number : '1.3-3'  }} {{selectedMesaure}}</td>

           <td *ngIf="!euCenterFeature">{{this.inventoryBreakDown.STOCK.SGS.physical  | number : '1.3-3'    }} {{selectedMesaure}}</td>
           <td *ngIf="!euCenterFeature">{{this.inventoryBreakDown.STOCK.SGS.reserved  | number : '1.3-3'    }} {{selectedMesaure}}</td>
           <td *ngIf="!euCenterFeature">{{this.inventoryBreakDown.STOCK.SGS.available | number : '1.3-3'    }} {{selectedMesaure}}</td>
         </tr>
         <tr class="mi-bold">
           <td>Sub-Total</td>
           <td>{{this.inventoryBreakDown.STOCK.SUB.physical  | number : '1.3-3'  }} {{selectedMesaure}}</td>
           <td>{{this.inventoryBreakDown.STOCK.SUB.reserved  | number : '1.3-3'  }} {{selectedMesaure}}</td>
           <td>{{this.inventoryBreakDown.STOCK.SUB.available | number : '1.3-3'  }} {{selectedMesaure}}</td>
         </tr>
       </tbody>
      <tbody [hidden]="repoName=='COFORM'">
         <tr>
           <td rowspan=3 class="mi-bold">GLP</td>
           <td>Indianapolis</td>
           <td>{{this.inventoryBreakDown.GLP.INDY.physical  | number : '1.3-3'  }} {{selectedMesaure}}</td>
           <td>{{this.inventoryBreakDown.GLP.INDY.reserved  | number : '1.3-3'  }} {{selectedMesaure}}</td>
           <td>{{this.inventoryBreakDown.GLP.INDY.available | number : '1.3-3'  }} {{selectedMesaure}}</td>
         </tr>
         <tr>
           <td>{{ euCenterFeature ? "France" : "SGS" }}</td>
           <td *ngIf="euCenterFeature">{{this.inventoryBreakDown.GLP.FRANCE.physical  | number : '1.3-3'  }} {{selectedMesaure}}</td>
           <td *ngIf="euCenterFeature">{{this.inventoryBreakDown.GLP.FRANCE.reserved  | number : '1.3-3'  }} {{selectedMesaure}}</td>
           <td *ngIf="euCenterFeature">{{this.inventoryBreakDown.GLP.FRANCE.available | number : '1.3-3'  }} {{selectedMesaure}}</td>

           <td *ngIf="!euCenterFeature">{{this.inventoryBreakDown.GLP.SGS.physical  | number : '1.3-3'    }} {{selectedMesaure}}</td>
           <td *ngIf="!euCenterFeature">{{this.inventoryBreakDown.GLP.SGS.reserved  | number : '1.3-3'    }} {{selectedMesaure}}</td>
           <td *ngIf="!euCenterFeature">{{this.inventoryBreakDown.GLP.SGS.available | number : '1.3-3'    }} {{selectedMesaure}}</td>
         </tr>
         <tr class="mi-bold">
           <td>Sub-Total</td>
           <td>{{this.inventoryBreakDown.GLP.SUB.physical  | number : '1.3-3'  }} {{selectedMesaure}}</td>
           <td>{{this.inventoryBreakDown.GLP.SUB.reserved  | number : '1.3-3'  }} {{selectedMesaure}}</td>
           <td>{{this.inventoryBreakDown.GLP.SUB.available | number : '1.3-3'  }} {{selectedMesaure}}</td>
         </tr>
       </tbody>
       <tbody [hidden]="repoName=='COFORM'">
         <tr class="mi-bold">
           <td>Grand Total</td>
           <td></td>
           <td> {{materialInfo.totalPhysical | number : '1.3-3'  }} {{selectedMesaure}}</td>
           <td> {{ (materialInfo.totalIndyReserved + (euCenterFeature ? materialInfo.totalFranceReserved : materialInfo.totalSGSReserved ))  | number : '1.3-3'  }} {{selectedMesaure}}</td>
           <td> {{ inventoryTotalAvailable | number : '1.3-3' }} {{selectedMesaure}}</td>
         </tr>
       </tbody>
       </table>
       </div>

    <div class="quick-links col-2" style="text-align: right;">
      <a style="text-decoration: underline;font-size: 14px;" href="{{materialSearchSettings.factsUrl}}">*FACTS</a>
      <a style="text-decoration: underline;font-size: 14px;margin-top:-0.1rem;"
        href="{{materialSearchSettings.coreUrl}}">*Compound Utilities</a>
      <a style="text-decoration: underline;font-size: 14px;margin-top:-0.1rem;"
        href="{{materialSearchSettings.raceUrl}}">*RACE</a>
      <div class="col-12" style="text-align: right;">
        <span style="font-weight: bold;">*Requires VPN</span>
      </div>
    </div>
  </div>


  <div style="text-align: center; padding-top: 10px;">
    <div class="disclaimer">Disclaimer:
      <span>Total Reserved and Total Available quantity may not add up to the line item's available quantities</span>
    </div>
    <div class="disclaimer">Note: <span>Use Repo Lot No. / ID for reservations</span></div>
  </div>

  <div class="material-details">
    <div class="material-measures">
      <div class="measures">
        *Unit of Measure: <p-selectButton [options]="stateOptions" (onChange)="OnUnitOfMeasureChange($event)"
          [(ngModel)]="selectedUom" optionLabel="uomDisplay"></p-selectButton>
      </div>
      <div class="last-update-time">
        <div class="time">Last Updated: {{updatedTimeStamp | date: 'dd-MMM-yy h:mm:ss a'}}</div>
      </div>
      <div style="margin-left: 5px;">
        <p-selectButton [options]="inventoryList"
          [(ngModel)]="selectedInventoryType" optionLabel="name" optionValue="value"></p-selectButton>
      </div>
      <div class="last-update-time">
        <button type="button" pButton icon="pi pi-download" pTooltip="Download Reconciliation Report" class="btn-icons"
          id="btnDownload" *ngIf="isAdministrator || isMaterialPlanner || isOrderCoordinator" tooltipPosition="bottom"
          (click)="downloadReconcileReport()"></button>
        <button type="button" pButton icon="pi pi-refresh" pTooltip="Reconciliation has been temporarily disabled" class="btn-icons"
          id="btnRefresh" *ngIf="isAdministrator || isMaterialPlanner || isOrderCoordinator" tooltipPosition="bottom"
          (click)="Reconcile()" disabled></button>
      </div>

    </div>
    <div class="inventory-details">
      <p-table [columns]="cols" [value]="materialInfo.compositeInventoryByLot">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <!-- <th *ngFor="let col of columns">
              {{col.header}}
            </th> -->
            <th *ngIf="repoName=='FACTS' || repoName == 'COFORM' || repoName == 'BIONEXUS'" pSortableColumn="lotNumber">*Repo Lot No./ID <p-sortIcon field="lotNumber"></p-sortIcon></th>
            <th *ngIf="repoName=='CORE'" pSortableColumn="lotId"> *Repo Lot No./ID <p-sortIcon field="lotId"></p-sortIcon></th>
            <th pSortableColumn="manufacturerLotNumber"> Mfg Lot No <p-sortIcon field="manufacturerLotNumber"></p-sortIcon></th>
            <th pSortableColumn="dateOfManufacture"> Date of Mfg <p-sortIcon field="dateOfManufacture"></p-sortIcon></th>
            <th pSortableColumn="expirationDate"> Exp Date <p-sortIcon field="expirationDate"></p-sortIcon></th>
            <th pSortableColumn="inventoryType"> Inventory Type <p-sortIcon field="inventoryType"></p-sortIcon></th>
            <th pSortableColumn="inventoryLocation"> Warehouse Loc. <p-sortIcon field="inventoryLocation"></p-sortIcon></th>
            <th pSortableColumn="physical"> Physical <p-sortIcon field="physical"></p-sortIcon></th>
            <th pSortableColumn="reserved"> Reserved<p-sortIcon field="reserved"></p-sortIcon></th>
            <th pSortableColumn="inProgress"> In Progress <p-sortIcon field="inProgress"></p-sortIcon></th>
            <th pSortableColumn="available" style="text-align: right !important;"> Available Qty <p-sortIcon field="available"></p-sortIcon></th>
            <th pSortableColumn="isReach"> REACH <p-sortIcon field="isReach"></p-sortIcon></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr *ngIf="rowData['available'] != '0' &&
           ((selectedInventoryType == 'STOCK' && rowData['inventoryType'].toLowerCase().includes('stock')) || (selectedInventoryType == 'GLP' && rowData['inventoryType'].toLowerCase().includes('glp')) || (selectedInventoryType == 'BOTH'))">
            <td *ngFor="let col of columns" [ngClass]="col.field">
              <ng-container
                *ngIf="col.field === 'dateOfManufacture' || col.field === 'expirationDate'; else nonDateFields">
                {{ rowData[col.field] | date:"dd-MMM-yyyy" :'UTC'}}
              </ng-container>
              <ng-container #nonDateFields *ngIf="col.field === 'reserved'; else nonReserved">
                {{(euCenterFeature ? (rowData["reserved"] | number : '1.3-3') : (rowData["inventoryLocation"] == "Indy"
                ? (rowData["indyReserved"] | number : '1.3-3') : (rowData["sgsReserved"] | number : '1.3-3')))}}
                {{selectedUom?.uomDisplay}}
              </ng-container>
              <ng-template #nonReserved>
                <ng-container
                  *ngIf="col.field === 'physical'|| col.field === 'inProgress' || col.field === 'available'; else nonUOMFields">
                  <ng-container *ngIf="rowData[col.field]; else noValue">
                    {{rowData[col.field] | number : '1.3-3'}} {{selectedUom?.uomDisplay}}
                  </ng-container>
                  <ng-template #noValue>
                    {{rowData[col.field]}}
                  </ng-template>
                </ng-container>
                <ng-template #nonUOMFields>
                  <ng-container *ngIf="col.field === 'isReach'; else nonReachFields">
                    {{rowData[col.field]!=undefined ? rowData[col.field]?'Yes' : 'No':''}}
                  </ng-container>
                  <ng-template #nonReachFields>

                    <a *ngIf='col.field==="lotNumber"||col.field==="lotId"' title='copy to clipboard'
                      style='text-decoration: underline;cursor: pointer;'
                      (click)='copyToClipboard(rowData[col.field])'>{{rowData[col.field]}}</a>
                    <span
                      *ngIf='col.field!=="lotNumber"&&col.field!=="lotId" && col.field !== "dateOfManufacture" && col.field !== "expirationDate" && col.field!="inventoryLocation"'>{{rowData[col.field]}}</span>
                    <span *ngIf='col.field=="inventoryLocation"'>
                       {{ euCenterFeature ? rowData[col.field]  : (rowData[col.field] == "Indy" ? "Indianapolis" : rowData[col.field]) }}
                    </span>
                  </ng-template>
                </ng-template>
              </ng-template>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
