import { EnvironmentDefinition } from "./environment-defination.interface"
import { InteractionType, BrowserCacheLocation } from "@azure/msal-browser";

/*
    This is the class where i can define the key and its value
    and it will transform the value of the key based on environment.

    --> If you want to add a new key and its value in the below section
        then first you define that key in the EnvironmentDefinition interface.
*/
export class Environments {

    public static environments: EnvironmentDefinition[] = [
        {
            name: 'LOCAL',
            hosts: ['localhost'],
            ShellAPIPath: 'https://localhost:44393',
            ShellUIPath: 'http://localhost:4200',
            clientID: 'e103380a-57ef-4bda-9e6a-46967ff03adf',
            authority: 'https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4',
            cpssApiPath: 'https://cpsampleshopApi-qa.se.research.corteva.com',
            appInsightsKey: '6ff138f8-4e8d-4c27-9f36-d057226b21e3',
            faqUrl: "https://confluence.research.corteva.com/display/CPKB/FARM",
            helpUrl: "https://confluence.research.corteva.com/display/CPKB/FARM",
            appVersion: "v3.0.0",
            msal: {
                config: {
                    auth: {
                        clientId: "e103380a-57ef-4bda-9e6a-46967ff03adf",
                        authority: "https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4/",
                        redirectUri: "http://localhost:4200/after-login",
                        postLogoutRedirectUri: "http://localhost:4200/after-logout",
                        navigateToLoginRequestUrl: true
                    },
                    cache: {
                        cacheLocation: BrowserCacheLocation.LocalStorage
                    }
                },
                interceptor: {
                    interactionType: InteractionType.Redirect,
                    protectedResourceMap: new Map<string, string[]>([
                        ['https://localhost:44393', ['api://gssforecasting-nonprod/Api.Write', 'api://gssforecasting-nonprod/Api.Read']],
                        ['https://localhost:44390', ['api://6c532256-e670-4e57-bc85-3949fb1b4b62/ReadAccess']],
                        ['https://cpsampleshopApi-qa.se.research.corteva.com', ['api://6c532256-e670-4e57-bc85-3949fb1b4b62/ReadAccess']],
                        ['https://gsscommon-api-dev.azurewebsites.net/', ['api://gsscommon-nonprod/API.FullAccess']]

                    ])
                },
                guard: {
                    interactionType: InteractionType.Redirect,
                    "authRequest": {
                        "scopes": ["api://gssforecasting-nonprod/Api.Read","api://gssforecasting-nonprod/Api.Write"]
                      }
                }
            },
            featureFlags: {
                formulationProduction: true,
                euCenter: false
            },
            COMPOSITE_SEARCH_SETTINGS: {
                factsUrl: 'https://forecasting-dev.azurewebsites.net/',
                coreUrl: 'https://cp-compoundutilities-dev.phibred.com/',
                raceUrl: 'https://raced.phibred.com/Home/Index',
                hostAppApiUrl: 'https://localhost:4200',
                tokenEndPoint: '/api/gsscommon',
                gssCommonUrl: 'https://gsscommon-api-dev.azurewebsites.net',
                pageLength: 5,
                showCartButton: false,
                showInfoButton: false,
                useShowAllHideAll: true
            }
        },
        {
            name: 'DV',
            hosts: ['dev'],
            ShellAPIPath: 'https://farmApi-dev.se.research.corteva.com',
            ShellUIPath: 'https://farm-dev.se.research.corteva.com',
            clientID: 'e103380a-57ef-4bda-9e6a-46967ff03adf',
            authority: 'https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4',
            cpssApiPath: 'https://cpsampleshopApi-dev.se.research.corteva.com',
            appInsightsKey: '6ff138f8-4e8d-4c27-9f36-d057226b21e3',
            faqUrl: "https://confluence.research.corteva.com/display/CPKB/FARM",
            helpUrl: "https://confluence.research.corteva.com/display/CPKB/FARM",
            appVersion: "v3.0.0",
            msal: {
                config: {
                    auth: {
                        clientId: "e103380a-57ef-4bda-9e6a-46967ff03adf",
                        authority: "https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4/",
                        redirectUri: "https://farm-dev.se.research.corteva.com/after-login",
                        postLogoutRedirectUri: "https://farm-dev.se.research.corteva.com/after-logout",
                        navigateToLoginRequestUrl: true
                    },
                    cache: {
                        cacheLocation: BrowserCacheLocation.LocalStorage
                    }
                },
                interceptor: {
                    interactionType: InteractionType.Redirect,
                    protectedResourceMap: new Map<string, string[]>([
                        ['https://farmApi-dev.se.research.corteva.com', ['api://gssforecasting-nonprod/Api.Write', 'api://gssforecasting-nonprod/Api.Read']],
                        ['https://cpsampleshopApi-dev.se.research.corteva.com', ['api://6c532256-e670-4e57-bc85-3949fb1b4b62/ReadAccess']],
                        ['https://gsscommon-api-dev.azurewebsites.net/', ['api://gsscommon-nonprod/API.FullAccess']]

                    ])
                },
                guard: {
                    interactionType: InteractionType.Redirect,
                    "authRequest": {
                        "scopes": ["api://gssforecasting-nonprod/Api.Read","api://gssforecasting-nonprod/Api.Write"]
                      }
                }
            },
            featureFlags: {
                formulationProduction: true,
                euCenter: false
            },
            COMPOSITE_SEARCH_SETTINGS: {
                factsUrl: 'https://forecasting-dev.azurewebsites.net/',
                coreUrl: 'https://cp-compoundutilities-dev.phibred.com/',
                raceUrl: 'https://raced.phibred.com/Home/Index',
                hostAppApiUrl: 'https://farmApi-dev.se.research.corteva.com',
                tokenEndPoint: '/api/gsscommon',
                gssCommonUrl: 'https://gsscommon-api-dev.azurewebsites.net',
                pageLength: 5,
                showCartButton: false,
                showInfoButton: false,
                useShowAllHideAll: true
            }
        },
        {
            name: 'QA',
            hosts: ['qa'],
            ShellAPIPath: 'https://farmApi-qa.se.research.corteva.com',
            ShellUIPath: 'https://farm-qa.se.research.corteva.com',
            clientID: 'e103380a-57ef-4bda-9e6a-46967ff03adf',
            authority: 'https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4',
            cpssApiPath: 'https://cpsampleshopApi-qa.se.research.corteva.com',
            appInsightsKey: 'f8714d93-f0ed-4d79-9464-922659c42761',
            faqUrl: "https://confluence.research.corteva.com/display/CPKB/FARM",
            helpUrl: "https://confluence.research.corteva.com/display/CPKB/FARM",
            appVersion: "v3.0.0",
            msal: {
                config: {
                    auth: {
                        clientId: "e103380a-57ef-4bda-9e6a-46967ff03adf",
                        authority: "https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4/",
                        redirectUri: "https://farm-qa.se.research.corteva.com/after-login",
                        postLogoutRedirectUri: "https://farm-qa.se.research.corteva.com/after-logout",
                        navigateToLoginRequestUrl: true
                    },
                    cache: {
                        cacheLocation: BrowserCacheLocation.LocalStorage
                    }
                },
                interceptor: {
                    interactionType: InteractionType.Redirect,
                    protectedResourceMap: new Map<string, string[]>([
                        ['https://farmApi-qa.se.research.corteva.com', ['api://gssforecasting-nonprod/Api.Write', 'api://gssforecasting-nonprod/Api.Read']],
                        ['https://cpsampleshopApi-qa.se.research.corteva.com', ['api://6c532256-e670-4e57-bc85-3949fb1b4b62/ReadAccess']],
                        ['https://gsscommon-api-qa.azurewebsites.net/', ['api://gsscommon-nonprod/API.FullAccess']]

                    ])
                },
                guard: {
                    interactionType: InteractionType.Redirect,
                    "authRequest": {
                        "scopes": ["api://gssforecasting-nonprod/Api.Read","api://gssforecasting-nonprod/Api.Write"]
                      }
                }
            },
            featureFlags: {
                formulationProduction: true,
                euCenter: false
            },
            COMPOSITE_SEARCH_SETTINGS: {
                factsUrl: 'https://facts.qa.research.corteva.com/',
                coreUrl: 'https://cp-compoundutilities-test.phibred.com/',
                raceUrl: 'https://raceq.phibred.com/Home/Index',
                hostAppApiUrl: 'https://farmApi-qa.se.research.corteva.com',
                tokenEndPoint: '/api/gsscommon',
                gssCommonUrl: 'https://gsscommon-api-qa.azurewebsites.net',
                pageLength: 5,
                showCartButton: false,
                showInfoButton: false,
                useShowAllHideAll: true
            }
        },
        {
            name: 'UAT',
            hosts: ['uat'],
            ShellAPIPath: 'https://farmApi-uat.se.research.corteva.com',
            ShellUIPath: 'https://farm-uat.se.research.corteva.com',
            clientID: 'e103380a-57ef-4bda-9e6a-46967ff03adf',
            authority: 'https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4',
            cpssApiPath: 'https://cpsampleshopApi-uat.se.research.corteva.com',
            appInsightsKey: '43907d44-6e6b-433e-8f6b-7a301c32c986',
            faqUrl: "https://confluence.research.corteva.com/display/CPKB/FARM",
            helpUrl: "https://confluence.research.corteva.com/display/CPKB/FARM",
            appVersion: "v3.0.0",
            msal: {
                config: {
                    auth: {
                        clientId: "e103380a-57ef-4bda-9e6a-46967ff03adf",
                        authority: "https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4/",
                        redirectUri: "https://farm-uat.se.research.corteva.com/after-login",
                        postLogoutRedirectUri: "https://farm-uat.se.research.corteva.com/after-logout",
                        navigateToLoginRequestUrl: true
                    },
                    cache: {
                        cacheLocation: BrowserCacheLocation.LocalStorage
                    }
                },
                interceptor: {
                    interactionType: InteractionType.Redirect,
                    protectedResourceMap: new Map<string, string[]>([
                        ['https://farmApi-uat.se.research.corteva.com', ['api://gssforecasting-nonprod/Api.Write', 'api://gssforecasting-nonprod/Api.Read']],
                        ['https://cpsampleshopApi-uat.se.research.corteva.com', ['api://6c532256-e670-4e57-bc85-3949fb1b4b62/ReadAccess']],
                        ['https://gsscommon-api-uat.azurewebsites.net/', ['api://gsscommon-nonprod/API.FullAccess']]

                    ])
                },
                guard: {
                    interactionType: InteractionType.Redirect,
                    "authRequest": {
                        "scopes": ["api://gssforecasting-nonprod/Api.Read","api://gssforecasting-nonprod/Api.Write"]
                      }
                }
            },
            featureFlags: {
                formulationProduction: true,
                euCenter: false
            },
            COMPOSITE_SEARCH_SETTINGS: {
                factsUrl: 'https://facts.qa.research.corteva.com/',
                coreUrl: 'https://cp-compoundutilities-test.phibred.com/',
                raceUrl: 'https://raceq.phibred.com/Home/Index',
                hostAppApiUrl: 'https://farm-uat.se.research.corteva.com',
                tokenEndPoint: '/api/gsscommon',
                gssCommonUrl: 'https://gsscommon-api-uat.azurewebsites.net',
                pageLength: 5,
                showCartButton: false,
                showInfoButton: false,
                useShowAllHideAll: true
            }
        },
        {
            name: 'PR',
            hosts: ['prod','farm.'],
            ShellAPIPath: 'https://farmApi.se.research.corteva.com',
            ShellUIPath: 'https://farm.se.research.corteva.com',
            clientID: 'e0dadeb8-dba8-483b-badf-c561c486a402',
            authority: 'https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4',
            cpssApiPath: 'https://cpsampleshopApi.se.research.corteva.com',
            appInsightsKey: '258d79ff-ee4f-4d46-a712-51ffdd390b25',
            faqUrl: "https://confluence.research.corteva.com/display/CPKB/FARM",
            helpUrl: "https://confluence.research.corteva.com/display/CPKB/FARM",
            appVersion: "v3.0.0",
            msal: {
                config: {
                    auth: {
                        clientId: "e0dadeb8-dba8-483b-badf-c561c486a402",
                        authority: "https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4/",
                        redirectUri: "https://farm.se.research.corteva.com/after-login",
                        postLogoutRedirectUri: "https://farm.se.research.corteva.com/after-logout",
                        navigateToLoginRequestUrl: true
                    },
                    cache: {
                        cacheLocation: BrowserCacheLocation.LocalStorage
                    }
                },
                interceptor: {
                    interactionType: InteractionType.Redirect,
                    protectedResourceMap: new Map<string, string[]>([
                        ['https://farmApi.se.research.corteva.com', ['api://gssforecasting-prod/Api.Write', 'api://gssforecasting-prod/Api.Read']],
                        ['https://cpsampleshopApi.se.research.corteva.com', ['api://f0fb150c-2892-4c16-9215-808f583a6eee/ReadAccess']],
                        ['https://gsscommon-api-prod.azurewebsites.net/', ['api://gsscommon-prod/API.FullAccess']]

                    ])
                },
                guard: {
                    interactionType: InteractionType.Redirect,
                    "authRequest": {
                        "scopes": ["api://gssforecasting-prod/Api.Read","api://gssforecasting-prod/Api.Write"]
                      }
                }
            },
            featureFlags: {
                formulationProduction: true,
                euCenter: false
            },
            COMPOSITE_SEARCH_SETTINGS: {
                factsUrl: 'https://facts.research.corteva.com/',
                coreUrl: 'https://cp-compoundutilities.phibred.com/',
                raceUrl: 'https://race.phibred.com/Home/Index',
                hostAppApiUrl: 'https://Forecasting-prod.azurewebsites.net',
                tokenEndPoint: '/api/gsscommon',
                gssCommonUrl: 'https://gsscommon-api-prod.azurewebsites.net',
                pageLength: 5,
                showCartButton: false,
                showInfoButton: false,
                useShowAllHideAll: true
            }
        }
    ];
}
